import './Header.css';

export const Header = () => {
  return (
    <header>
      <h2>
        <a href='https://dailykaylee.com'>Kaylee</a>
      </h2>
    </header>
  );
}
